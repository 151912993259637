<script>

/*
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
*/
  import documentManager from "@/components/widgets/documentManager";
  
  import useVuelidate from "@vuelidate/core";

  import Layout from "../../../layouts/main.vue";
  import appConfig from "../../../../app.config";
  import PageHeader from "@/components/page-header";

  import serverConfig   from '@/helpers/config';

  import Swal from "sweetalert2";
  import axios from 'axios';
  import moment from 'moment';
  import brandSearchForm from "@/components/widgets/brandSearchForm";
  
  import salesRouteSearchForm from "@/components/widgets/salesRouteSearchForm";
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import { mapActions, mapGetters} from "vuex";

  export default {
    page: {
      title: "Modifica prodotto",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    setup() {
      moment.locale('it')
      const store = useStore();
 
      let tk = 'Bearer '+localStorage.getItem('tk')
      let brands = computed(() => store.getters["ecommercesettings/getBrands"])


      return {
        v$: useVuelidate(),
        tk,
        brands
      };
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        user:this.$store.state.auth,
        title: "Modifica prodotto",
        min_date: moment().toISOString(),
        optionsStatus:[
          { value: 'Published', label: 'Pubblicato' },
          { value: 'Scheduled', label: 'Schedulato' },
          { value: 'Draft', label: 'Bozza' },
        ],
        optionsVisibility:[
          { value: 'Public', label: 'Pubblico' },
          { value: 'Hidden', label: 'Nascosto' },
        ],
        optionsUM:[
          { value: 'PZ', label: 'Pezzo' },
          { value: 'MC', label: 'Metro cubo' },
          { value: 'ML', label: 'Metro lineare' },
          { value: 'KG', label: 'Kilogrammi' },
          { value: 'CL', label: 'Collo' },

        ],
        item:{
/**
 *      { "low_availability": { "choice": "NO" }, 
 *      "on_demand": { "choice": "NO" }, 
 *      "position": 0, 
 *      "giacenzaEffettiva": 0, 
 *      "size": "", "colors": [], 
 *      "status": "init", 
 *      "packagingLength": 0, 
 *       "packagingWidth": 0, 
 *       "packagingHeight": 0, "packagingWeight": 0, "packagingThickness": 0, "tokenizer": [], 
 *       "_id": "64abe23d0eb6500ba5518a22", 
 *       "Listino": "01", 
 *       "Famiglia": "Split", 
 *       "Tipologia": "Componenti", 
 *        "Refrigerante": "R32", 
 *       "GruppoSconto": "Residenziale", 
 *       "gcode": "FTXA20AW", 
 *         "ean": "4548848657117", 
 *       "CodMerc": "84159000", 
 *       "Descrizione": "Stylish R32 interna white 20", 
 *       "FamECR": "", 
 *       "Parametro": "", "ECR": "", 
 *        "Febbraio 2023": "510.02", 
 *        "Stato": "", "Lordo": "13.50", " Netto": "11.50", 
 *         "um": "KG", "Lunghezza": 0.88, "Larghezza": 0.4, "Altezza": 0.3, "Unità": "M", "Volume": 0.11, "UV": "M3", "Volume_2": 106, "UV_2": "DM3", "TipoApparecchiature": "", "TipologiaInst": "", "Circuiti": "", "TipologiaFGAS": "", "QtaKg": "", "createdDate": "2023-07-10T13:52:08.116Z", "id": "64abe23d0eb6500ba5518a22" }
 */    
          um:'',
          Famiglia:'',
          Tipologia:'',
          Refrigerante:'',
          GruppoSconto:'',
          gcode:'',
          ean:'',
          CodMerc:'',
          Nome:'',
          slug:'',
          Descrizione:'',
          FamECR:'',
          Parametro:'',
          ECR:'',
          Prezzo:0.00,
          Lunghezza:0.00,
          Larghezza:0.00,
          Altezza:0.00,
          Unita:'',
          Volume:0.00,
          UV:'',
          Volume_2:0.00,
          UV_2:'',
          TipoApparecchiature:'',
          TipologiaInst:'',
          Circuiti:'',
          TipologiaFGAS:'',
          QtaKg:0.00,
          CodFamiglia: '',
          CodMarca: '',
          CodIva:22,
          publishSchedule: moment.unix(),
          publishScheduleISO: moment().toISOString(),
        },
        retailPrice:false,
        items: [
          {
            text: "Magazzino",
            href: "/warehouse/products",
          },
          {
            text: "Modifica prodotto",
            active: true,
          },
        ],
        date1: null,
        datetimeConfig: {
          enableTime: true,
          dateFormat: "DD/MM/YYYY",
          time_24hr: true,
        },
        value1: ["Scheduled"],
        value2: ["Hidden"],
        value3: ["Fashion"],
        editor: '',
        editorData: "",
        content: "<h1>Some initial content</h1>",
        categoriesList:[],
        optionsCat:[],
        optionsSubCat:[],
        optionsSubCatFiltered:[],
        salesRouteUser:{},
        compID:false,
        imgCoverPath:false,
        syncESStatus:false,
      };
    },
    components: {
      Layout,
      PageHeader,
      brandSearchForm,
      salesRouteSearchForm,
      documentManager,
      //Multiselect,
    },
    computed:{
      ...mapGetters('ecommercesettings', ['getBrands']),
    },
    mounted() {
      if(this.user.currentUser){
        if (this.user.currentUser.id){
          this.getSalesRouteList(this.user.currentUser.id)
        }
      }
      
      if (this.$route.query.dbgr && this.$route.query.filterBy && this.$route.query.paginateSettings&&this.$route.query.syncroESAction&&this.$route.query.value ){
        this.items = [
          {
            text: "Magazzino",
            href: `/warehouse/products?dbgr=${this.$route.query.dbgr}&filterBy=${this.$route.query.filterBy}&paginateSettings=${this.$route.query.paginateSettings}&syncroESAction=${this.$route.query.syncroESAction}&value=${this.$route.query.value}`,
          },
          {
            text: "Modifica prodotto",
            active: true,
          },
        ]
      }

      this.getItem()
    },
    methods: {
      ...mapActions('ecommercesettings', ['getBrands']),
      axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }                
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
    },
    getParent(){
       this.getItem()
      },
      Capitalize(key){
        this.item[key].toUpperCase()
      },
      syncroApp() {
        Swal.fire(
          {
            title: this.$t("t-wa-sync-confirm-setting-title"),
            text: this.$t("t-wa-sync-confirm-setting-text"),
            icon: this.$t("t-wa-sync-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-wa-sync-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-wa-sync-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-wa-sync-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-wa-sync-confirm-setting-confirmButtonText")
          }
        ).then((result) => {
         
          if (result.isConfirmed) {
              this.axiosInterceptor()
              let data = {
                CodArt: this.item.CodArt.toUpperCase(),
                Descrizione: this.item.Nome,
                um: this.item.um,
                um2: this.item.um2,
                quantity: this.item.quantity,
                quantity_2: this.item.quantity_2,
                CodIva: this.item.CodIva,
                CodFAM: this.item.CodFAM.toUpperCase(),
                CodMarca: this.item.CodMarca.toUpperCase(),
                CodContoAcquisto:"",
                CodContoVendita:"",
                Prezzo: this.item.Prezzo,
              }
              axios.post(`${this.UrlServer}esync/create-artones`, data,
                { 
                  headers: { 'authorization':this.tk},
                  onUploadProgress: (event) =>
                  {
                    const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                    let timerInterval;
                    Swal.fire({
                      title: "Caricamento...",
                      html: "Progresso operazione <b></b>.",
                      timer: percentUploaded,
                      timerProgressBar: true,
                      didOpen: () => {
                        Swal.showLoading();
                        /*
                        const timer = Swal.getPopup().querySelector("b");
                        timerInterval = setInterval(() => {
                          timer.textContent = `${Swal.getTimerLeft()}`;
                        }, 100);
                        */  
                      },
                      willClose: () => {
                        clearInterval(timerInterval);
                      }
                    }).then((result) => {
                      /* Read more about handling dismissals below */
                      if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('Caricamento...')
                      }
                    }).catch( error=>{
                      console.log(JSON.stringify(error))
                    })

                  },
                 
                }).then(response=>{

                  if (response.data) {
                    if (response.data.status == 'OK' && response.data.results =='data') {
                     // Swal.fire(this.$t("t-msg-import") , this.$t("t-msg-content-import"), "success");

                      Swal.fire({
                        title: this.$t("t-msg-import"),
                        text: this.$t("t-msg-content-import"),
                        icon:"success",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:2000,
                      }).then(()=>{
                        window.location.reload()
                      })

                    } else {
                      Swal.fire("Problema importazione" , "Si è verificato un problema e non è stato possibile effettuare la sincronizzazione", "danger");
                    
                    }
                  } else {
                    Swal.fire("Problema importazione" , "Si è verificato un problema e non è stato possibile effettuare la sincronizzazione", "danger");
                  }
                }).catch(error => {                 
                  if (error) {
                    Swal.fire("Problema importazione" , "Si è verificato un problema e non è stato possibile effettuare la sincronizzazione", "danger");
                  }
                })
          }
        });
      },
      formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.',',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      deleteRecord(ele) {
        ele.target.parentElement.parentElement.remove();
      },
      slugify()
      { 
        this.item.slug = this.item.Nome.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'').trim()
        this.updateItem()
      },
      setDate(){
        this.item.publishSchedule = moment(this.item.publishScheduleISO).unix()
      },
      getSalesRouteList(id){
        let tk = localStorage.getItem('tk')
        
        axios.get(`${this.UrlServer}sales-route/srlcompany/${id}`,  
            {headers: { authorization:`Bearer ${tk}`}} 
          ).then((response)=>{
            if (response.data){
              this.salesRouteUser = response.data[0]
              this.compID = response.data[0].companyID
            }
            
          })          
      },  
      discCalcBase(price, discount){ 
        if (discount>0) {
          return this.formatPrice(price - ((price*discount)/100))
        }
      },
      discCalcFam(price, discount, discount2){
        if (discount2>0) {
          let price_1 = price - ((price*discount)/100)
          return this.formatPrice(price_1 - ((price_1*discount2)/100) )
        }
      },      
      getItem() {
      this.data = {}
        axios.get(`${this.UrlServer}products/${this.$route.params.id}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data && response.data.length > 0) {
            
            this.item = response.data[0]
            if (!response.data[0].Nome){
              this.item.Nome = response.data[0].Descrizione.slice(0,50)
            }
            if (!response.data[0].CodIva){
              this.item.CodIva = 22
            }
            this.checkItemsInES()

            this.editorData = response.data[0].Descrizione
            if(response.data[0].image){
              if (response.data[0].image!=''){
             
                this.getPathList(response.data[0].image)
              }
            }
            let self = this
            setTimeout(()=>{
                self.getRetailPrice(self.item.CodiceGruppo, self.salesRouteUser.companyID)           
            },2000)
          
          }                  
      })
    },
          
    getRetailPrice(codiceGruppo, compID) {
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}sales-route/search/retail-price/list`,  { 
          params: { 
            codiceGruppo: codiceGruppo,
            companyID: compID,
            
          }, 
          headers: { 'authorization':this.tk}
         }).then((response) => {
          if (response.data) {
            this.retailPrice = response.data
          }                  
      })
    },
    getPathList(data) {
      let l = [{path:data}]
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}storage/db/by/file-path-list`, 
        { 
          params: { 
            fileList: l,
          }, 
          headers: { 'authorization':this.tk}
        }
      ).then(response=>{
          if (response.data.length>0){
            this.imgCoverPath  = response.data[0].url
          }
          
        })
    },
    getCategories() {
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}categories/list`, { headers: { authorization:this.tk } }).then((response) => {
          if (response.data) {
            this.categoriesList = response.data
            this.setOptions('categories', this.categoriesList)
          }                  
      })
    },
    setOptions(type,listv){
      if (type=='categories'){
       for (let item of listv){
        if (!item.type_doc){
           if (item.type_doc=='child'){
            this.optionsSubCat.push(item)
           }
        } else {
           this.optionsCat.push(item)
        }
       }
      }
    },
    updateItem() {
      this.axiosInterceptor()
      axios.put(`${this.UrlServer}products/${this.$route.params.id}`,this.item, {headers: { authorization:this.tk}}).then((response) => {
        console.log(response.data)               
      })
    },
    updateRetailPrice(id, item) {
      this.axiosInterceptor()
      axios.put(`${this.UrlServer}products/retail-price/${id}`,item, {headers: { authorization:this.tk}}).then((response) => {
          console.log("response.data")
          console.log(response.data)
          this.getItem()            
      })
    },
    
    createRetailPrice() {
      if (this.retailPrice.Prezzo>0){
        this.retailPrice.CodArt = this.item.gcode
        this.retailPrice.ean = this.item.ean
        this.retailPrice.CodMerc = this.item.CodMerc
        this.retailPrice.Famiglia = this.item.Famiglia
        this.retailPrice.GruppoSconto = this.item.GruppoSconto
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}products/retail-price/create`,this.retailPrice, {headers: { authorization:this.tk}}).then((response) => {
          console.log(response.data)
          this.getItem()            
        })
      }
    },
    changeCat(){
      let self = this
      if (this.item.Famiglia!=''){
        let famiglia = this.categoriesList.filter(function(el){
          return el.code == self.item.Famiglia
        })
        if (famiglia){
         
            console.log(famiglia[0]._id)
            for (let item of this.optionsSubCat) {
              console.log(item._id)
            }
          
        }
      }

    },
    updatePrintLabel(id,value) {
      this.axiosInterceptor()
      axios.put(`${this.UrlServer}products/${id}`,{"printLabel":value}, {headers: { authorization:this.tk}}).then((response) => {
        if (response) {
          this.exportLabelsButton = true
          Swal.fire({
            title:"Esportazione etichetta",
            text:"Articolo inserito nella lista delle etichetta da stampare",
            icon:"success",
            showCancelButton:false,
            showConfirmButton:false,
            timer:2500,
          })  
        }      
      })
    },
    checkItemsInES(){
      axios.post(`${this.UrlServer}esync/check-item-sync`,{items:[this.item.CodArt]}, 
        { 
          headers: { 'authorization':this.tk},
        },
       ).then( response => {
          if (response.data) {
           if (response.data.status=='no-data') {
            this.syncESStatus = false
           } else {
            this.syncESStatus = true
           }
          }
      })
    },

    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-8">
        <form>
          <div class="card">
            <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1" >
              Scheda prodotto
            </h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
              
                <template v-if="item.syncES==true">
                  <span class="mdi mdi-24px mdi-check text-green pr-5 "> Sincronizzato con ESolver</span>
                </template>
                <template v-else>
                  <span class="mdi mdi-24px mdi-close-thick text-red pr-5 "> Non sincronizzato con ESolver</span>
                </template>
                <template v-if="item.CodArt">
                  <template v-if="item.CodArt.length > 0">
                    <template v-if="item.syncES==true">
                      <b-button variant="outline-success" class="waves-effect waves-light"  title="Sincronizza dati con gestionale" @click="syncroApp">
                        <i class="mdi mdi-swap-horizontal align-bottom me-1"></i>
                        Aggiorna ESolver
                      </b-button>
                    </template>
                    <template v-else>
                      <b-button variant="outline-danger" class="waves-effect waves-light"  title="Sincronizza dati con gestionale" @click="syncroApp">
                        <i class="mdi mdi-swap-horizontal align-bottom me-1"></i>
                        Invia scheda all'ESolver
                      </b-button>
                    </template>
                  </template>
                  <template v-else>
                    <b-button variant="outline-danger" class="waves-effect waves-light"  title="Sincronizza dati con gestionale" @click="syncroApp">
                      <i class="mdi mdi-swap-horizontal align-bottom me-1"></i>
                        Invia scheda all'ESolver
                    </b-button>
                  </template>
                </template>
                &nbsp;
              </div>
            </div>
          </div>
            <div class="card-body">
              <div class="row back-grey">
                <div class="col-lg-12 col-md-12 mb-20 mt-20">
                  <h4>Informazioni obbligatorie per trasmissione a gestionale</h4>
                </div>
              </div>
              <div class="row back-grey">
                <div class="col-lg-6 col-md-12 mb-20">
                  <div class="mb-3" >
                    <div >
                      <label class="form-label" for="product-title-input">{{ $t("t-product-name") }}</label>
                      <input type="text" class="form-control" id="product-title-input" placeholder="Inserisci nome prodotto" v-model="item.Nome" @blur="slugify(),updateItem"/>
                    </div>
                   </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="mb-3" >
                    <div >
                      <label class="form-label" for="product-gcode-input">Codice generico</label>
                        <div class="input-group mb-3">
                          <span class="input-group-text" id="product-gcode-addon">G</span>
                            <input type="text" class="form-control" id="product-gcode-input" placeholder="Inserisci codice generico"
                                  aria-label="gcode" aria-describedby="product-gcode-addon" v-model="item.CodArt" @input="Capitalize('CodArt')" @blur="updateItem" />
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="mb-3">
                    <label class="form-label" for="product-ean-input">Codice EAN</label>
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="product-ean-addon">EAN</span>
                          <input type="text" class="form-control" id="product-ean-input"
                                placeholder="Inserisci EAN" aria-label="ean"
                                aria-describedby="product-ean-addon"  v-model="item.ean" @blur="updateItem"/>
                      </div>
                  </div>
                </div>                      
              </div>
            <div class="row back-grey">
                <div class="col-lg-3 col-md-12 back-blue-light">
                  <div class="mb-3" >
                    <div >
                      <label class="form-label bold" for="product-um2-input">{{ $t("t-product-um2") }}</label>
                      <select class="form-select" v-model="item.um2" @change="updateItem">
                        <option v-for="item in optionsUM" :key="item" :value="item.value">
                          {{ item.label }}
                        </option>
                      </select>
                      </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-12 back-magenta-light">
                  <div class="mb-3" >
                    <div >
                      <label class="form-label bold" for="product-um-input">{{ $t("t-product-um") }}</label>
                      <select class="form-select" v-model="item.um" @change="updateItem">
                        <option v-for="item in optionsUM" :key="item" :value="item.value">
                          {{ item.label }}
                        </option>
                      </select>
                    </div>
                   </div>
                </div>
                <div class="col-lg-2 col-md-12 back-blue-light">
                  <div class="mb-3">
                    <label class="form-label bold" for="product-quantity_2-input">{{ $t("t-product-quantity_2") }}</label>
                    <input type="number" class="form-control" id="product-quantity_2-input" placeholder="Quantità di aquisto" aria-label="Quantità di acquisto" aria-describedby="product-quantity-addon"  v-model="item.quantity_2" @blur="updateItem" min="0" />
                  </div>
                </div>
                <div class="col-lg-2 col-md-12 back-magenta-light">
                  <div >
                    <label class="form-label bold" for="product-quantity-input">{{ $t("t-product-quantity") }}</label>
                    <input type="number" class="form-control" id="product-quantity-input" placeholder="Quantità di vendita" aria-label="Quantità di vendita" aria-describedby="product-quantity-addon"  v-model="item.quantity" @blur="updateItem" min="0"/>
                  </div>
                </div>   

                <div class="col-lg-2 col-md-12">
                  <div class="mb-3">
                  </div>
                </div>                     
            </div>
            <div class="row back-grey">
                <div class="col-lg-3 col-md-12 ">
                  <div class="mb-3" >
                    <div >
                      <label class="form-label" for="product-price-input">Prezzo di listino</label>                     
                        <div class="input-group mb-3">
                          <span class="input-group-text" id="product-gcode-addon">&euro;</span>
                          <input type="number" class="form-control" id="product-price-input" placeholder="Prezzo di listino" aria-label="Prezzo di listino" aria-describedby="product-price-addon"  v-model="item.Prezzo" @blur="updateItem"  />
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-12 ">
                  <div class="mb-3" >
                    <div >
                      <div class="mb-3">
                        <label class="form-label" for="product-cod-iva-input">Codice Iva</label>
                          <input type="number" class="form-control" id="product-cod-iva-input" placeholder="Codice Iva" aria-label="Codice Iva" aria-describedby="product-cod-iva-addon"  v-model="item.CodIva" @blur="updateItem" min="0" max="22" />
                        </div>
                    </div>
                   </div>
                </div>
                <div class="col-lg-2 col-md-12 ">
                  <div class="mb-3" >
                    <div >
                      <div class="mb-3">
                        <label class="form-label" for="product-CodFAM-input">Codice Famiglia</label>
                          <input type="text" class="form-control" id="product-CodFAM-input" placeholder="Codice Famiglia" aria-label="Codice Famiglia" aria-describedby="product-CodFAM-addon"  v-model="item.CodFAM" @blur="updateItem" />
                        </div>
                    </div>
                   </div>
                </div>
                <div class="col-lg-2 col-md-12 ">
                  <div class="mb-3" >
                    <div >
                      <div class="mb-3">
                        <label class="form-label" for="product-CodMarca-input">Codice Marca</label>
                          <input type="text" class="form-control" id="product-CodMarca-input" placeholder="Codice Marca" aria-label="Codice Marca" aria-describedby="product-CodMarca-addon"  v-model="item.CodMarca" @blur="updateItem" />
                        </div>
                    </div>
                   </div>
                </div>   

                <div class="col-lg-2 col-md-12">

                </div>                     
            </div>
            <div class="row mt-10 mb-10">
              <div class="col-lg-12 col-md-12">
                <div class="mb-3">
                  <label class="form-label" for="product-title-input">{{ $t("t-slug-name") }}</label>
                  <input type="text" class="form-control" id="product-slug-input" placeholder="Slug nome prodotto" v-model="item.slug" @blur="updateItem" />
                </div>
              </div>
            </div>
            <div class="row  mt-10 mb-10">
              <div class="col-lg-12 col-md-12">
              <div class="mb-3">
                <label class="form-label" for="product-title-input">URL Immagine di copertina</label>
                <input type="text" class="form-control" id="product-slug-input" placeholder="Slug nome prodotto" v-model="item.image" @blur="updateItem" />
              </div>
              </div>
            </div>
            <div class="row  mt-10 mb-10">
              <div class="col-lg-12 col-md-12">
                <div class="mb-3" v-if="imgCoverPath!=false">
                <label class="form-label" for="product-title-input">Immagine di copertina</label><br>
                  <b-img :src="imgCoverPath" class="img-fluid" alt="Immagine di copertina"> </b-img> 
                </div>
              </div>
            </div>
            <div class="row  mt-10 mb-10">
              <div class="col-lg-12 col-md-12">
              <div class="mb-3">
                <label>{{ $t("t-product-description") }}</label>
               
                <quill-editor v-model="item.Descrizione" @blur="updateItem" ></quill-editor>
                
              </div>
            </div>
            </div>
            </div>
          </div>
          
          <!-- end card -->



          <div class="card">
            <div class="card-header">
              <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" data-bs-toggle="tab" href="#addproduct-general-info" role="tab">
                    Informazioni Generali
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#addproduct-metadata" role="tab">
                    Meta Dati
                  </a>
                </li>
              </ul>
            </div>
            <!-- end card header -->
            <div class="card-body">
              <div class="tab-content">
                <div class="tab-pane active" id="addproduct-general-info" role="tabpanel">
                  <div class="row">
                    <div class="col-lg-4 col-md-12">
                      <div class="mb-3 ml-20 pl-20 pt-10 ">                         
                          <label for="marca" class="form-label">Marca</label>
                          <div class="">
                            <template v-if="item.marca && item.marca !='' ">
                              <h3> {{ item.marca }}</h3>
                            </template>
                            <template v-else>
                              Marca non indicata
                            </template>
                          </div>
                          <div class="mb-50">
                            <brandSearchForm :ID="item._id" type="product"  :urlPut="`${this.UrlServer}products/${item._id}`" ></brandSearchForm>
                          </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 background-btn-grey-1 mb-50">
                      <div class="mb-3 ml-20 pl-20 pt-10 ">                         
                          <label for="marca" class="form-label">Famiglia Gruppo Sconto</label>
                          <div class="">
                            <template v-if="item.nomeGruppoSconto && item.nomeGruppoSconto !='' ">
                              <h3 class="mb-20"> {{ item.nomeGruppoSconto }}</h3>
                            </template>
                            <template v-else>
                              <span  class="mb-20">Gruppo sconto non indicato</span>
                            </template>
                          </div>
                          <div class="mb-50 mt-20">
                            <salesRouteSearchForm :ID="item._id" type="product" :brandCode="item.CodMarca" :urlPut="`${this.UrlServer}products/${item._id}`" ></salesRouteSearchForm>
                          </div>
                        </div>
                    </div>
                    
                  </div>

                  <div class="row mb-50">                    
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="mb-50 mt-50">
                            <label class="form-label" for="product-price-input">Prezzo di listino</label>
                            <div class="input-group mb-3">
                              <template v-if="item.price_list && item.price_list.length > 0">
                                <span class="input-group-text" id="product-price-addon">&euro;  <strong class="pl-5">{{ formatPrice(item.price_list[0].Prezzo) }}</strong></span>
                              </template>
                              <!--<template v-if="item.price_list && item.price_list.length > 0">
                                <input type="number" v-model="item.price_list[0].Prezzo" class="form-control" id="product-price-input" placeholder="Inserisci prezzo"
                                  aria-label="Prezzo di listino" aria-describedby="product-price-addon" @blur="updateRetailPrice(item.price_list[0]._id, item.price_list[0])" />
                              </template>
                              <template v-else>
                                <input type="number" v-model="retailPrice.Prezzo" class="form-control" id="product-price-input" placeholder="Inserisci prezzo"
                                aria-label="Prezzo di listino" aria-describedby="product-price-addon" @blur="createRetailPrice" />
                              </template>-->
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="mb-50 mt-50">
                            <label class="form-label" for="product-price-input">Prezzo con sconto base</label>
                            <div class="input-group mb-3">
                              <template v-if="retailPrice">
                                <span class="input-group-text" id="product-price-addon"> (- {{retailPrice.scontoPercBase}}%) &euro;  <strong class="pl-5" v-if="item.price_list.length>0">{{ discCalcBase(item.price_list[0].Prezzo, retailPrice.scontoPercBase) }}</strong></span>
                              </template>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="mb-50 mt-50">
                            <label class="form-label" for="product-price-input">Prezzo con sconto famiglia</label>
                            <div class="input-group mb-3">
                              <template v-if="retailPrice">
                                <span class="input-group-text" id="product-price-addon"> (- {{retailPrice.scontoPercAggiuntivo}}%) &euro;  <strong class="pl-5" v-if="item.price_list.length>0">{{ discCalcFam(item.price_list[0].Prezzo, retailPrice.scontoPercBase, retailPrice.scontoPercAggiuntivo) }}</strong></span>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <!-- end row -->
                </div>
                <!-- end tab-pane -->

                <div class="tab-pane" id="addproduct-metadata" role="tabpanel">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label" for="meta-title-input">Meta titolo</label>
                        <input type="text" class="form-control" placeholder="Inserisci meta titolo" id="meta-title-input" />
                      </div>
                    </div>
                    <!-- end col -->

                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label" for="meta-keywords-input">Meta Keywords</label>
                        <input type="text" class="form-control" placeholder="Inserisci meta keywords"
                          id="meta-keywords-input" />
                      </div>
                    </div>
                    <!-- end col -->
                  </div>
                  <!-- end row -->

                  <div>
                    <label class="form-label" for="meta-description-input">Meta Descrizione</label>
                    <textarea class="form-control" id="meta-description-input" placeholder="Inserisci meta descrizione"
                      rows="3"></textarea>
                  </div>
                </div>
                <!-- end tab pane -->
              </div>
              <!-- end tab content -->
            </div>
            <!-- end card body -->



            
          </div>
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0">Galleria immagini e allegati</h5>
            </div>
            <div class="card-body">
            <!--
                :projectID="data._id" 
                  :data="data" 
            -->
            <template v-if="item._id">
              <documentManager 
                  :productID="item._id"
                  :projectID="item._id"
                  :productCode="item.gcode"
                  :productEan="item.ean"
                  :url="`${this.UrlServer}storage/by/project`"
                  :data="item" 
                  bucket="store/images" 
                  type="product-gallery" 
                  fileType="multiple" 
                  fileAccept="*" 
                  title="Gestisci allegati"
                  v-on:getParent="getParent()"/>
            </template>

              
            </div>
          </div>
          <!-- end card -->
          <!-- end card -->
          <div class="text-end mb-3">
            
          </div>
        </form>
      </div>
      <!-- end col -->

      <div class="col-lg-4">
        <div class="card">

          <div class="card-header">
            <h5 class="card-title mb-0">
              <div class="form-check form-switch form-switch-info">
                Esporta etichetta
                <input class="form-check-input" type="checkbox" role="switch" v-model="item.printLabel" @change="updatePrintLabel(item._id,item.printLabel)" >
              </div>

            </h5>
          </div>
          <div class="card-header">
            <h5 class="card-title mb-0">Stato pubblicazione</h5>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="choices-publish-status-input" class="form-label">Magazzino</label>
              <div class="mb-3">
                
                  <select class="form-select" v-model="item.DBGruppo" @change="updateItem">
                    <option value="nd">Seleziona Magazzino</option>
                    <option value="SP">Sunservice</option>
                    <option value="ES">S.Energy Solutions</option>
                  </select>
              </div>  
            </div>
          </div>
          <div class="card-header">
            <h5 class="card-title mb-0">Stato pubblicazione</h5>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="choices-publish-status-input" class="form-label">Status</label>

              <select class="form-select" v-model="item.status" @change="updateItem">
                <option v-for="item in optionsStatus" :key="item" :value="item.value">
                  {{ item.label }}
                </option>
              </select>

            </div>

            <div>
              <label for="choices-publish-visibility-input" class="form-label">Visibilità</label>
              <select class="form-select" v-model="item.visibility" @change="updateItem">
                <option v-for="item in optionsVisibility" :key="item" :value="item.value">
                  {{ item.label }}
                </option>
              </select>

            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->

        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Pianifica pubblicazione</h5>
          </div>
          <!-- end card body -->
          <div class="card-body">
            <div>
              <label for="datepicker-publish-input" class="form-label">Data e orario pubblicazione</label>
                <input type="datetime-local" class="form-control" id="meeting-time" name="meeting-time"  :min="min_date"  v-model="item.publishScheduleISO" @change="setDate">
            </div>
          </div>
        </div>
        <!-- end card -->

        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Famiglia (Categoria)</h5>
          </div>
          <div class="card-body">
            <p class="text-muted mb-2">
              <a href="#" class="float-end text-decoration-underline">Aggiungi nuova</a>Seleziona famiglia
            </p>
            <select class="form-select" v-model="item.Famiglia" @change="changeCat()"  >
              <option v-for="item in optionsCat" :value="item.code" :key="item._id"> {{ item.name }} </option>
            </select>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Sotto Famiglia (Sotto-Categoria)</h5>
          </div>
          <div class="card-body">
            <pre>{{optionsSubCatFiltered}}</pre>
            <p class="text-muted mb-2">
              <a href="#" class="float-end text-decoration-underline">Aggiungi nuova</a>Seleziona famiglia 
            </p>
            <select class="form-select" v-model="item.sottoFamiglia"   >
              <option v-for="item in optionsSubCatFiltered"  :value="item.code" :key="item._id"> {{ item.name }}</option>
            </select>
          </div>
        </div>
      <!--  <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Tipologia prodotto</h5>
          </div>
          <div class="card-body">
            <p class="text-muted mb-2">
              <a href="#" class="float-end text-decoration-underline">Aggiungi nuova</a>Seleziona categoria prodotto
            </p>
            <Multiselect class="form-control" v-model="item.Tipologia" :close-on-select="true" :searchable="true"
              :create-option="true" :options="[
                { value: 'componenti', label: 'Componenti' },
                { value: 'set', label: 'Set' },
              
              ]" />
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Refrigerante</h5>
          </div>
          <div class="card-body">
            <p class="text-muted mb-2">
              <a href="#" class="float-end text-decoration-underline">Aggiungi nuova</a>Seleziona Refrigerante
            </p>
            <Multiselect class="form-control" v-model="item.Refrigerante" :close-on-select="true" :searchable="true"
              :create-option="true" :options="[
                { value: '', label: 'Nessuno' },
                { value: 'R32', label: 'R32' },
              
              ]" />
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Gruppo Sconto</h5>
          </div>
          <div class="card-body">
            <p class="text-muted mb-2">
              <a href="#" class="float-end text-decoration-underline">Aggiungi nuovo</a>Seleziona Gruppo Sconto
            </p>
            <Multiselect class="form-control" v-model="item.GruppoSconto" :close-on-select="true" :searchable="true"
              :create-option="true" :options="[
                { value: '', label: 'Nessuno' },
                { value: 'Accessori', label: 'R32' },
              
              ]" />
          </div>
        </div>-->
      <!--
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Tagga prodotto</h5>
          </div>
          <div class="card-body">
            <div class="hstack gap-3 align-items-start">
              <div class="flex-grow-1">
                <input class="form-control" data-choices data-choices-multiple-remove="true" placeholder="Enter tags"
                  type="text" value="Cotton"  />
              </div>
            </div>
          </div>
          
        </div>
    
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Product Short Description</h5>
          </div>
          <div class="card-body">
            <p class="text-muted mb-2">Add short description for product</p>
            <textarea class="form-control" placeholder="Must enter minimum of a 100 characters" rows="3"></textarea>
          </div>
          
        </div>
      -->
      </div>
    </div>
    
  </Layout>
</template>